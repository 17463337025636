#et-top-navigation #myjr-auth-dropdown {
  display: inline-flex;
  position: relative;
  top: -2px;
  margin-left: 0px;
  margin-right: 10px;
}

#et-top-navigation .AuthDropdown {
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-flex;
  align-items: center;
  line-height: 1.25;
}

#et-top-navigation .AuthDropdown__Inner {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10000;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px hsl(230deg 50% 50% / 10%);
  overflow: hidden;
  padding: 15px;
  width: 240px;
}

#et-top-navigation .AuthDropdown__Inner > div:first-child {
  font-size: 12px;
  margin-bottom: 10px;
  word-break: break-word;
  text-align: center;
}

#et-top-navigation .AuthDropdown__Inner > div:nth-child(3) {
  font-size: 12px;
  color: #666;
  text-align: center;
  margin: 5px;
}

#et-top-navigation .AuthDropdown__Inner > div:first-child > span {
  font-size: 12px;
  font-weight: bold;
}

#et-top-navigation .AuthDropdown__Inner button {
  /* all: unset; */
  background-image: linear-gradient(135deg, #b400e1, #1ca0ea);
  border-color: transparent;
  border-radius: 8px;
  box-shadow: 0 3px 10px -5px rgb(9 28 79 / 50%);
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  padding: 8px 25px;
  position: relative;
  transition: all 0.2s;
  border: none;
  display: block;
  width: 100%;
}

#et-top-navigation .AuthDropdown__Toggle {
  all: unset;
  color: inherit;
  cursor: pointer;
  display: flex;
  color: white;
  transition-duration: .25s;
  transition-property: color;
}

#et-top-navigation .AuthDropdown__Toggle:hover {
  color: rgba(255,255,255,.5);
}

#et-top-navigation .AuthDropdown__Toggle svg {
  width: 36px;
  height: 36px;
}

@media (min-width: 981px) {
  #et-top-navigation #myjr-auth-dropdown {
    position: relative;
    top: -12px;
    margin-left: 25px;
    margin-right: 0px;
  }

  .et-fixed-header #et-top-navigation .AuthDropdown__Toggle {
    color: #222;
  }

  .et-fixed-header #et-top-navigation .AuthDropdown__Toggle:hover {
    color: #666;
  }
}